import { formatText } from 'utils/formatting';
import Context from './ValidationContext';
import { ValidationTexts } from '../constants';
import createRequiredValidator from './required';
import createStringLengthValidator from './stringLength';
import createRegexValidator from './regex';
import createPasswordStrengthValidator from './passwordStrength';
import createEqualsToValidator from './equalsTo';
import createNotEqualsToValidator from './notEqualsTo';
import createUniqueEmailValidator from './uniqueEmail';
import createUniqueOrderTemplateValidator from './uniqueOrderTemplate';
import createRangeValidator from './range';
import createPreciseValidator from './precise';
import createDateValidator from './date';
import createDatesRangeValidator from './datesRange';
import createCountryDependentRegexValidator from './countryDependentRegex';
import createMinAgeValidator from './minAge';
import createFileAcceptedTypesValidator from './fileAcceptedTypes';
import createFileNameMaxLengthValidator from './fileNameMaxLength';
import createFilesMaxSizeValidator from './filesMaxSize';
import createFilesMaxCountValidator from './filesMaxCount';
import { emailRegex, readableNameRegex } from './patterns';
import { toLocaleDate } from 'utils/format';
import { defaultMinDate, defaultMaxDate } from 'components/primitives/form';

const validators = Object.freeze({
  'required': {
    factory: ({ message, fieldTitle, allowWhitespace, validateAgainstFalse }) => {
      const formattedMessage = formatText(message, [fieldTitle]);
      const params = { allowWhitespace, validateAgainstFalse };
      return createRequiredValidator(new Context(formattedMessage, params));
    },
    defaultMessageKey: ValidationTexts.Required,
  },
  'stringLength': {
    factory: ({ message, fieldTitle, max, min }) => {
      const formattedMessage = formatText(message, [fieldTitle, max, min]);
      return createStringLengthValidator(new Context(formattedMessage, { max, min }));
    },
    defaultMessageKey: ValidationTexts.OutOfRange,
  },
  'maxLength': {
    factory: ({ message, fieldTitle, max }) => {
      const formattedMessage = formatText(message, [fieldTitle, max]);
      return createStringLengthValidator(new Context(formattedMessage, { max }));
    },
    defaultMessageKey: ValidationTexts.MaxLengthExceeded,
  },
  'regex': {
    factory: ({ message, fieldTitle, pattern }) => {
      const formattedMessage = formatText(message, [fieldTitle]);
      return createRegexValidator(new Context(formattedMessage, { pattern }));
    },
    defaultMessageKey: ValidationTexts.Invalid,
  },
  'emailAddress': {
    factory: options => validators.regex.factory({ ...options, pattern: emailRegex }),
    defaultMessageKey: ValidationTexts.Invalid,
  },
  'readableName': {
    factory: options => validators.regex.factory({ ...options, pattern: readableNameRegex }),
    defaultMessageKey: ValidationTexts.InvalidReadableField,
  },
  'passwordStrength': {
    factory: ({ message, fieldTitle, minimumScore, userInputs }) => {
      const formattedMessage = formatText(message, [fieldTitle]);
      return createPasswordStrengthValidator(new Context(formattedMessage, { minimumScore, userInputs }));
    },
    defaultMessageKey: ValidationTexts.PasswordStrength,
  },
  'equalsTo': {
    factory: ({ message, fieldTitle, to }) => {
      const formattedMessage = formatText(message, [fieldTitle]);
      return createEqualsToValidator(new Context(formattedMessage, { to }));
    },
  },
  'notEqualsTo': {
    factory: ({ message, fieldTitle, to }) => {
      const formattedMessage = formatText(message, [fieldTitle]);
      return createNotEqualsToValidator(new Context(formattedMessage, { to }));
    },
  },
  'uniqueEmail': {
    factory: ({ message, fieldTitle }) => {
      const formattedMessage = formatText(message, [fieldTitle]);
      return createUniqueEmailValidator(new Context(formattedMessage));
    },
    defaultMessageKey: ValidationTexts.UniqueEmail,
  },
  'range': {
    factory: ({ message, fieldTitle, min, max }) => {
      const formattedMessage = formatText(message, [fieldTitle, min, max]);
      return createRangeValidator(new Context(formattedMessage, { min, max }));
    },
    defaultMessageKey: ValidationTexts.Range,
  },
  'precise': {
    factory: ({ message, precision }) => {
      const formattedMessage = formatText(message, [precision]);
      return createPreciseValidator(new Context(formattedMessage, { precision }));
    },
    defaultMessageKey: ValidationTexts.Precise,
  },
  'uniqueOrderTemplate': {
    factory: ({ message, fieldTitle }) => {
      const formattedMessage = formatText(message, [fieldTitle]);
      return createUniqueOrderTemplateValidator(new Context(formattedMessage));
    },
    defaultMessageKey: ValidationTexts.UniqueOrderTemplate,
  },
  'date': {
    factory: ({ message, fieldTitle }) => {
      const formattedMessage = formatText(message, [fieldTitle]);
      return createDateValidator(new Context(formattedMessage));
    },
    defaultMessageKey: ValidationTexts.Invalid,
  },
  'datesRange': {
    factory: ({ message, culture, minDate = defaultMinDate, maxDate = defaultMaxDate }) => {
      const localeMinDate = toLocaleDate(minDate, culture);
      const localeMaxDate = toLocaleDate(maxDate, culture);
      const formattedMessage = formatText(message, [localeMinDate, localeMaxDate]);
      return createDatesRangeValidator(new Context(formattedMessage, { minDate, maxDate }));
    },
    defaultMessageKey: ValidationTexts.DatesRange,
  },
  'countryDependentRegex': {
    factory: ({ message, fieldTitle, perCountryPatterns, countryFieldName, countryId }) => {
      const params = { fieldTitle, perCountryPatterns, countryFieldName, countryId };
      return createCountryDependentRegexValidator(new Context(message, params));
    },
    defaultMessageKey: ValidationTexts.PerCountryPatterns,
  },
  'minAge': {
    factory: ({ message, minAge, culture }) => {
      if (!minAge)
        return;

      const now = new Date();
      const maxDate = new Date(now.getFullYear() - minAge, now.getMonth(), now.getDate());

      const formattedMessage = formatText(message, [toLocaleDate(maxDate, culture)]);
      return createMinAgeValidator(new Context(formattedMessage, { maxDate }));
    },
    defaultMessageKey: ValidationTexts.MinAge,
  },
  'fileAcceptedTypes': {
    factory: ({ message, types }) => {
      const formattedMessage = formatText(message, [types.join(', ')]);
      return createFileAcceptedTypesValidator(new Context(formattedMessage, { types }));
    },
    defaultMessageKey: ValidationTexts.FileAcceptedTypes,
  },
  'fileNameMaxLength': {
    factory: ({ message, max }) => {
      const formattedMessage = formatText(message, [max]);
      return createFileNameMaxLengthValidator(new Context(formattedMessage, { max }));
    },
    defaultMessageKey: ValidationTexts.FileNameMaxLength,
  },
  'filesMaxSize': {
    factory: ({ message, max }) => {
      const maxSizeInMb = max / (1024 * 1024);
      const formattedMessage = formatText(message, [maxSizeInMb]);
      return createFilesMaxSizeValidator(new Context(formattedMessage, { max }));
    },
    defaultMessageKey: ValidationTexts.FilesMaxSize,
  },
  'filesMaxCount': {
    factory: ({ message, max }) => {
      const formattedMessage = formatText(message, [max]);
      return createFilesMaxCountValidator(new Context(formattedMessage, { max }));
    },
    defaultMessageKey: ValidationTexts.FilesMaxCount,
  },
});

export default validators;
